import { CCApiService } from './api-service';
import { Capability, Customer, UIConfig, ClientCredentials } from './interfaces';
import _ from 'lodash';

export class CustomersService {
  static ApiService: CCApiService;
  static customersAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customersAPI = `${this.apiBasePath}/customers`;
  }

  static async getAllCustomers(): Promise<Customer[]> {
    return this.ApiService.performFetch(`${this.customersAPI}`).then(async (firstPage) => {
      let remainingCustomers: any = [];

      // place requests for the remaining pages if required
      // Example: Total pages: 5, Remaining pages: 4
      // First Page is Page 0, Request customers from Pages 1-4
      if(firstPage.totalPages > 1) {
        const remainingPages = firstPage.totalPages - 1;
        const requestRemainingPages = _.times(remainingPages, async (page) => Promise.resolve(await this.getCustomers({page: page + 1})));
        await Promise.all(requestRemainingPages).then((response) => {
          remainingCustomers = _.flattenDeep(response);
        });
      }
      return [...firstPage.content, ...remainingCustomers];
    });
  }

  static async getCustomers(filter = {}): Promise<Customer[]> {
    return this.ApiService.performFetch(`${this.customersAPI}?${new URLSearchParams(filter)}`).then(customers => {
      return customers.content;
    });
  }

  static async getCustomer(customerId: string): Promise<Customer> {
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}`).then(customer => {
      return customer;
    });
  }

  static async getCustomerCredentials(customerId: string): Promise<ClientCredentials> {
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/credentials`).then(credentials => {
      return credentials;
    });
  }

  static async getCustomerCapabilities(): Promise<Capability[]> {
    return this.ApiService.performFetch(`${this.apiBasePath}/capabilities`).then(capabilities => {
      return capabilities;
    });
  }

  static async getCustomerIntegrations(filter: {}, customerId): Promise<any> {
    return this.ApiService.performFetch(`${this.customersAPI}/${customerId}/integrations?${new URLSearchParams(filter)}`).then(capabilities => {
      return capabilities;
    });
  }

  static async getCustomerIntegrationCredentials(integrationType: string, id: string): Promise<ClientCredentials> {
    return this.ApiService.performFetch(`${this.apiBasePath}/integrations/${integrationType}/${id}/credentials`).then(capabilities => {
      return capabilities;
    });
  }
}
